<div
  *ngIf="!error"
  #box
  cdkDrag
  class="box"
  [ngClass]="{
    dn: loading,
    dragging: dragging,
  }"
  [ngStyle]="{
    width: boxw + 'px',
    height: boxh + 'px',
    'min-width': boxw + 'px',
    'min-height': boxh + 'px',
  }"
  (cdkDragStarted)="dragging = true"
  (cdkDragEnded)="boxDragEnd($event)"
  (click)="boxClick($event)"
  (wheel)="zoom($event.deltaY * -1)"
  (pinchin)="zoom(-2)"
  (pinchout)="zoom(2)">
  <div
    *ngFor="let m of markings"
    #markingTarget
    class="marking {{ m.model }} {{ getUserRelationForCase(profile, m.Case) }}"
    [ngClass]="{
      completed: !!m?.Case?.completed,
      dragging: dragging,
      highlighted: isMarkingHighlighted(m),
      offline: !m?.Case?.createdAt,
      other: m?.Case?.CustomerId !== profile?.CustomerId,
      'has-color': validColor(m?.Case?.Field?.color || m?.Case?.ObjectField?.color),
    }"
    cdkDragBoundary=".box"
    cdkDrag
    cdkOverlayOrigin
    [cdkDragFreeDragPosition]="getMarkingPos(m)"
    (cdkDragStarted)="dragging = true"
    (cdkDragEnded)="markingDragEnd($event, m)"
    [cdkDragDisabled]="!edit"
    [ngStyle]="{
      width: mr + 'px',
      height: mr + 'px',
    }"
    [style.--field-color]="m?.Case?.Field?.color || m?.Case?.ObjectField?.color"
    (mouseenter)="markingEnter.emit({ marking: m, target: markingTarget })"
    (mouseleave)="markingLeave.emit({ marking: m, target: markingTarget })">
    <div
      *ngIf="m.Case"
      class="marking-text mat-caption">
      {{ m.Case.id }}
    </div>
    <div
      class="marking-mark df ac jc"
      (click)="$event.stopPropagation(); !dragging && markingClicked.emit(m)"></div>
  </div>
  <img
    #image
    class="w-100 h-100"
    [alt]="file?.name"
    [style.transform]="'rotate(' + degrees + 'deg)'"
    [src]="file?.signed?.imageUrl | urlCache"
    (load)="loading = false; resize()"
    (error)="error = true" />
</div>
<ng-container *ngIf="!loading && !error">
  <div class="image-tools df c amb">
    <button
      mat-icon-button
      [matTooltip]="'Zoom in' | translate"
      (click)="zoom(10)">
      <mat-icon> zoom_in</mat-icon>
    </button>
    <button
      mat-icon-button
      [matTooltip]="'Zoom out' | translate"
      type="button"
      (click)="zoom(-10)">
      <mat-icon> zoom_out</mat-icon>
    </button>
    <button
      mat-icon-button
      [matTooltip]="'Rotate counter-clockwise' | translate"
      type="button"
      (click)="degrees = degrees - 90">
      <mat-icon> rotate_left</mat-icon>
    </button>
    <button
      mat-icon-button
      [matTooltip]="'Rotate clockwise' | translate"
      type="button"
      (click)="degrees = degrees + 90">
      <mat-icon> rotate_right</mat-icon>
    </button>
    <button
      mat-icon-button
      [matTooltip]="'Original size' | translate"
      type="button"
      (click)="resize(true)">
      <mat-icon> aspect_ratio</mat-icon>
    </button>
    <button
      mat-icon-button
      [matTooltip]="'Reset' | translate"
      type="button"
      (click)="degrees = 0; resize()">
      <mat-icon> crop_original</mat-icon>
    </button>
  </div>
  <div class="file-tools df ac">
    <button
      *ngIf="fullscreenEnabled"
      mat-icon-button
      [matTooltip]="'Fullscreen' | translate"
      (click)="toggleFullscreen()">
      <mat-icon>{{ fullscreen ? 'fullscreen_exit' : 'fullscreen' }}</mat-icon>
    </button>
  </div>
</ng-container>
<mat-spinner *ngIf="loading"></mat-spinner>
<div
  *ngIf="error"
  class="placeholder-image w-100 h-100"></div>
