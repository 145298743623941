import { SignedUrls } from '../../models/file';

export const imageFileTypes = ['image/gif', 'image/jpg', 'image/jpeg', 'image/png', 'image/webp'];

export const defaultSize = {
  width: 192,
  height: 192,
};
export const defaultFileIcon = 'insert_drive_file';
export const videoFileIcon = 'movie';
export const pdfFileIcon = 'picture_as_pdf';
export const defaultImageQuality = 75;
export const defaultFit: ImageFit = 'cover';
export type ImageFit = 'scale-down' | 'contain' | 'cover' | 'crop' | 'pad';

export type FileLike = {
  signed?: SignedUrls;
};

export const getThumbUrlForFile = (
  file: FileLike,
  width = defaultSize.width,
  height = defaultSize.height,
  quality = defaultImageQuality,
  fit: ImageFit = defaultFit,
): string => {
  if (!file?.signed?.url) {
    throw new Error('File does not have a signed url');
  }

  return (
    `https://thumb.apexapp.io/cdn-cgi/image/` +
    `width=${width},height=${height},fit=${fit.trim()},` +
    `quality=${quality}/${file.signed.url}`
  );
};

export const getResizedUrlForFile = (
  file: FileLike,
  width?: number,
  height?: number,
  quality = defaultImageQuality,
  fit: ImageFit = defaultFit,
): string => {
  if (!file?.signed?.url) {
    throw new Error('File does not have a signed url');
  }

  let base = `https://thumb.apexapp.io/cdn-cgi/image/fit=${fit.trim()},quality=${quality}`;

  if (width && width > 0) {
    base += `,width=${width}`;
  }

  if (height && height > 0) {
    base += `,height=${height}`;
  }

  return `${base}/${file.signed.url}`;
};
